var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useContext, useEffect, useState } from 'react';
import { apolloClient } from '../ApolloClient';
import { AssemblyApiSearchDocument, AssemblyApiSearchResultCountDocument, GetAssembliesByFiltersDocument, GetAssemblyCountByFiltersDocument, GetAssemblyIdsByFiltersDocument, GetAssemblyNumberInfoDocument, GetNavAssemblyDetailByIdDocument, GetNavAssemblyIdByFiltersDocument, Order_By, ProductApiMatchedAssemblyListDocument, useGetAssembliesByIdsQuery, useGetAssemblyCountByFiltersQuery, useGetAssemblyIdsByFiltersQuery, useSearchNavAssembliesQuery } from '../models/GQLGeneratedModels';
import { getAPISearchCriteriaBasedOnFilters } from './AssemblySearchAPIService';
import { ApplicationContext } from '../components/ApplicationProvider';
import { useLogger } from 'fmg-telemetry-react';
export var getAssembliesByAssemblyNumber = function (assemblyNumber) { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetAssemblyNumberInfoDocument, variables: { navAssemblyName: "".concat(assemblyNumber, "%") } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data.NavAssembly];
    }
}); }); };
export var getAssemblyDetailByAssemblyId = function (assemblyNumber) { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetNavAssemblyDetailByIdDocument, variables: { NavAssemblyId: assemblyNumber } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data.NavAssembly[0]];
    }
}); }); };
export var getAssemblyCountByFilters = function (filters) { return __awaiter(void 0, void 0, void 0, function () { var _result$data$NavAssem, result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetAssemblyCountByFiltersDocument, variables: { filters: filters } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, (_result$data$NavAssem = result.data.NavAssembly_aggregate.aggregate) === null || _result$data$NavAssem === void 0 ? void 0 : _result$data$NavAssem.count];
    }
}); }); };
export var getNavAssemblyIdByFiltersResults = function (filters) { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetNavAssemblyIdByFiltersDocument, variables: { filters: filters } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data.NavAssembly];
    }
}); }); };
export var getAssemblyFilterSearchResults = function (filters, limit, offset, orderBy) { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetAssembliesByFiltersDocument, variables: { filters: filters, limit: limit, offset: offset, order_by: orderBy } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data.NavAssembly];
    }
}); }); };
export var getAssemblyIdsByFilters = function (filters, limit, offset, orderBy) { return __awaiter(void 0, void 0, void 0, function () { var result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, apolloClient.query({ query: GetAssemblyIdsByFiltersDocument, variables: { filters: filters, limit: limit, offset: offset, order_by: orderBy } })];
        case 1:
            result = _a.sent();
            return [2 /*return*/, result.data.NavAssembly.map(function (navAssembly) { return navAssembly.NavAssemblyId; })];
    }
}); }); };
export var useGetAssemblyCountByFiltersQueryWrapper = function (filters) { var _data$NavAssembly_agg; var _a = useGetAssemblyCountByFiltersQuery({ variables: { filters: filters } }), data = _a.data, loading = _a.loading, error = _a.error; return { count: data === null || data === void 0 ? void 0 : (_data$NavAssembly_agg = data.NavAssembly_aggregate.aggregate) === null || _data$NavAssembly_agg === void 0 ? void 0 : _data$NavAssembly_agg.count, countLoading: loading, countError: error }; };
export var useGetAssembliesByFiltersDoubleQuery = function (filters, limit, offset, orderBy) {
    var _a = useGetAssemblyIdsByFiltersQuery({ variables: { filters: filters, limit: limit, offset: offset, order_by: orderBy } }), data = _a.data, loading = _a.loading, error = _a.error;
    var ids = data === null || data === void 0 ? void 0 : data.NavAssembly.map(function (navAssembly) { return navAssembly.NavAssemblyId; });
    if (loading) {
        ids = [-1]; // return { data: undefined, loading, error };
    }
    return useGetAssembliesByIdsQuery({ variables: { ids: { NavAssemblyId: { _in: ids } }, order_by: orderBy } });
};
export var useGetAssembliesByVariousQuery = function (filters, limit, offset, orderBy) {
    //   filters.NavLayers &&
    //   filters.NavLayers?.NavUsages?.Usage?.Component?.ManufacturedProduct?.ManufacturerId &&
    //   Object.keys(filters).length === 2
    // ) {
    //   const mId = filters.NavLayers?.NavUsages?.Usage?.Component?.ManufacturedProduct?.ManufacturerId['_eq'];
    //   const idManufacturer = parseInt(String(mId));
    //   if (LARGEMANUFACTURERS.includes(idManufacturer)) {
    //     // Currently this branch is only used for large manufacturer as single search criteria except StatusId filter
    //     // To use SearchNavAssembliesByManufacturer, filters and order_by variables need to be in different format
    //     const filtersManufacturer: any = {}; // vNavAssembly_ManufacturerOnlySearch contains StatusId check
    //     filtersManufacturer['ManufacturerId'] = {
    //       _eq: idManufacturer,
    //     };
    //     const orderByManufacturer: any = {};
    //     orderByManufacturer['NavAssembly_ForManufacturerSearch'] = { ...orderBy };
    //     const { data, loading, error } = useSearchNavAssembliesByManufacturerQuery({
    //       variables: {
    //         filters: filtersManufacturer,
    //         limit: limit,
    //         offset: offset,
    //         order_by: orderByManufacturer,
    //       },
    //     });
    //     let ids = data?.vNavAssembly_ManufacturerOnlySearch.map((navAssembly) => navAssembly.NavAssemblyId);
    //     if (loading) {
    //       ids = [-1];
    //     }
    //     return useGetAssembliesByIdsQuery({
    //       variables: {
    //         ids: {
    //           NavAssemblyId: {
    //             _in: ids,
    //           },
    //         },
    //         order_by: orderBy,
    //       },
    //     });
    //   }
    // }
    return useSearchNavAssembliesQuery({ variables: { filters: filters, limit: limit, offset: offset, order_by: orderBy } });
};
export var useGetAssembliesByAPIQuery = function (layers, excludedLayerTypeIds, manufacturerProduct, limit, offset, orderBy, filters) { var logger = useLogger(); var _a = useState(), data = _a[0], setData = _a[1]; var _b = useState(true), loading = _b[0], setLoading = _b[1]; var _c = useState(), error = _c[0], setError = _c[1]; var autoRefreshSearchResult = useContext(ApplicationContext).autoRefreshSearchResult; var _d = useContext(ApplicationContext), triggerAssemblyList = _d.triggerAssemblyList, setTriggerAssemblyList = _d.setTriggerAssemblyList; var _e = useContext(ApplicationContext), numShowResult = _e.numShowResult, setNumShowResult = _e.setNumShowResult; useEffect(function () { if (filters && (autoRefreshSearchResult || triggerAssemblyList)) {
    setLoading(true);
    if (triggerAssemblyList)
        setTriggerAssemblyList(false);
    var assemblySearchCriteria_1 = getAPISearchCriteriaBasedOnFilters(layers, filters, excludedLayerTypeIds, manufacturerProduct, limit, offset, orderBy);
    apolloClient.query({ query: AssemblyApiSearchDocument, variables: { assemblySearchCriteria: assemblySearchCriteria_1 } }).then(function (result) { setData(result.data); setLoading(result.loading); setError(result.error); }).catch(function (error) { setError(error); setLoading(false); logger.error('Error in useGetAssembliesByAPIQuery', { searchCriteriaUsed: assemblySearchCriteria_1, error: error, msg: error.message }); });
} }, [limit, offset, JSON.stringify(orderBy), JSON.stringify(filters), numShowResult]); return { data: data, loading: loading, error: error }; };
export var useGetAssemblyCountByAPIQuery = function (layers, excludedLayerTypeIds, manufacturerProduct, filters) { var _data$navassembly_api, _data$navassembly_api2; var logger = useLogger(); var _a = useState(), data = _a[0], setData = _a[1]; var _b = useState(true), loading = _b[0], setLoading = _b[1]; var _c = useState(), error = _c[0], setError = _c[1]; var _d = useContext(ApplicationContext), autoRefreshSearchResult = _d.autoRefreshSearchResult, setAutoRefreshSearchResult = _d.setAutoRefreshSearchResult; var _e = useContext(ApplicationContext), triggerSearchResultCount = _e.triggerSearchResultCount, setTriggerSearchResultCount = _e.setTriggerSearchResultCount; var _f = useContext(ApplicationContext), numShowResult = _f.numShowResult, setNumShowResult = _f.setNumShowResult; useEffect(function () { if (filters && (autoRefreshSearchResult || triggerSearchResultCount)) {
    setLoading(true);
    if (triggerSearchResultCount)
        setTriggerSearchResultCount(false);
    var assemblySearchCriteria_2 = getAPISearchCriteriaBasedOnFilters(layers, filters, excludedLayerTypeIds, manufacturerProduct, 25, 0, { LayerConfigId: Order_By.Asc });
    apolloClient.query({ query: AssemblyApiSearchResultCountDocument, variables: { assemblySearchCriteria: assemblySearchCriteria_2 } }).then(function (result) { setData(result.data); setLoading(result.loading); setError(result.error); }).catch(function (error) { setError(error); setLoading(false); logger.error('Error in useGetAssemblyCountByAPIQuery', { searchCriteriaUsed: assemblySearchCriteria_2, error: error, msg: error.message }); });
} }, [JSON.stringify(filters), numShowResult]); return { count: data === null || data === void 0 ? void 0 : (_data$navassembly_api = data.navassembly_api_countsearchresult) === null || _data$navassembly_api === void 0 ? void 0 : (_data$navassembly_api2 = _data$navassembly_api[0]) === null || _data$navassembly_api2 === void 0 ? void 0 : _data$navassembly_api2.AssemblySearchResultCount, countLoading: loading, countError: error }; };
export var useGetAssembliesExtraRoofTypeIdByAPIQuery = function (layerConfigId, layers, excludedLayerTypeIds, manufacturerProduct, limit, offset, orderBy, filters) { var newFilters = _objectSpread({}, filters); newFilters['LayerConfigId'] = { _eq: layerConfigId }; return useGetAssembliesByAPIQuery(layers, excludedLayerTypeIds, manufacturerProduct, limit, offset, orderBy, newFilters); };
export var useGetAssembliesByComponentApprovedUse = function (componentId, approvedUseId, limit, offset, orderBy) {
    var _a = useState(), data = _a[0], setData = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(), error = _c[0], setError = _c[1];
    var assemblySearchCriteria = { componentId: componentId, approvedUseId: approvedUseId, rowsPerPage: 25, pageNumber: 1, sortColumn: 'NavAssemblyName', sortDirection: 0 }; // Paging and Sorting properties
    assemblySearchCriteria.rowsPerPage = limit;
    assemblySearchCriteria.pageNumber = offset / limit + 1;
    if (orderBy.NavAssemblyName) {
        assemblySearchCriteria.sortColumn = 'NavAssemblyName';
        assemblySearchCriteria.sortDirection = orderBy.NavAssemblyName === Order_By.Asc ? 0 : 1;
    }
    else if (orderBy.AssemblyTypeCode) {
        assemblySearchCriteria.sortColumn = 'AssemblyTypeCode';
        assemblySearchCriteria.sortDirection = orderBy.AssemblyTypeCode === Order_By.Asc ? 0 : 1;
    }
    else if (orderBy.AssemblyApplicationTypeCode) {
        assemblySearchCriteria.sortColumn = 'AssemblyApplicationTypeCode';
        assemblySearchCriteria.sortDirection = orderBy.AssemblyApplicationTypeCode === Order_By.Asc ? 0 : 1;
    }
    else if (orderBy.CoverSecurementMethodCode) {
        assemblySearchCriteria.sortColumn = 'CoverSecurementMethodCode';
        assemblySearchCriteria.sortDirection = orderBy.CoverSecurementMethodCode === Order_By.Asc ? 0 : 1;
    }
    else if (orderBy.DeckTypeCode) {
        assemblySearchCriteria.sortColumn = 'DeckTypeCode';
        assemblySearchCriteria.sortDirection = orderBy.DeckTypeCode === Order_By.Asc ? 0 : 1;
    }
    else if (orderBy.WindUplift || orderBy.WindUpliftValue) {
        assemblySearchCriteria.sortColumn = 'WindUplift';
        if (orderBy.WindUplift)
            assemblySearchCriteria.sortDirection = orderBy.WindUplift === Order_By.Asc ? 0 : 1;
        else
            assemblySearchCriteria.sortDirection = orderBy.WindUpliftValue === Order_By.Asc ? 0 : 1;
    }
    else if (orderBy.IntFireRatingCode) {
        assemblySearchCriteria.sortColumn = 'IntFireRatingCode';
        assemblySearchCriteria.sortDirection = orderBy.IntFireRatingCode === Order_By.Asc ? 0 : 1;
    }
    else if (orderBy.ExtFireRatingCode) {
        assemblySearchCriteria.sortColumn = 'ExtFireRatingCode';
        assemblySearchCriteria.sortDirection = orderBy.ExtFireRatingCode === Order_By.Asc ? 0 : 1;
    }
    else if (orderBy.Slope || orderBy.SlopeValue) {
        assemblySearchCriteria.sortColumn = 'Slope';
        if (orderBy.Slope)
            assemblySearchCriteria.sortDirection = orderBy.Slope === Order_By.Asc ? 0 : 1;
        else
            assemblySearchCriteria.sortDirection = orderBy.SlopeValue === Order_By.Asc ? 0 : 1;
    }
    else if (orderBy.HailRatingCode) {
        assemblySearchCriteria.sortColumn = 'HailRatingCode';
        assemblySearchCriteria.sortDirection = orderBy.HailRatingCode === Order_By.Asc ? 0 : 1;
    }
    else {
        assemblySearchCriteria.sortColumn = 'AssemblyNum';
        if (orderBy.AssemblyId)
            assemblySearchCriteria.sortDirection = orderBy.AssemblyId === Order_By.Asc ? 0 : 1;
        else
            assemblySearchCriteria.sortDirection = 0;
    }
    useEffect(function () { var logger = useLogger(); setLoading(true); apolloClient.query({ query: ProductApiMatchedAssemblyListDocument, variables: { productMatchAssemblyListCriteria: assemblySearchCriteria } }).then(function (result) { setData(result.data); setLoading(result.loading); setError(result.error); }).catch(function (error) { setError(error); setLoading(false); logger.error('Error in useGetAssembliesByComponentApprovedUse', { searchCriteriaUsed: assemblySearchCriteria, error: error.error, msg: error.message }); }); }, [limit, offset, JSON.stringify(orderBy), componentId, approvedUseId]);
    return { data: data, loading: loading, error: error };
};
