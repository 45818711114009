var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { LAYER_DATA, getFlatLayerData, getLayerProps } from '../components/constants/LAYER_DATA';
import { getLayerDataById } from './LayerTypeCheckUtilities';
import { DetermineSelfSecurementLayerType, DetermineStandardSecurementLayerType } from './SecurementUtilities';
export var numToString = function (num) { return num ? String(num).padStart(2, '0') : '00'; };
var findLayerPos = function (layers, layerTypeId, layerNum) { var count = 0; for (var i = 0; i < layers.length; i++) {
    var layer = layers[i];
    if (layer.id === numToString(layerTypeId))
        count++;
    if (count === layerNum)
        return i;
} return -1; };
var getLayerNum = function (layers, index) {
    var strict = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var layer = layers[index];
    var layerData = getLayerDataById(layer.id);
    var layerProps = getLayerProps(layerData);
    var layerNum = '(-[0-9])?';
    if (layerProps.hasDuplicates) {
        var relativeSeqNum = 1, numOfDuplicates = 0;
        for (var i = 0; i < layers.length; i++) {
            if (layer.isSecurementLayer && layers[i].isSecurementLayer)
                numOfDuplicates++;
            else if (layers[i].id === layer.id)
                numOfDuplicates++;
        }
        for (var i = 0; i < index; i++) {
            if (layer.isSecurementLayer && layers[i].isSecurementLayer)
                relativeSeqNum++;
            else if (layers[i].id === layer.id)
                relativeSeqNum++;
        } //layerNum = numOfDuplicates == 1 ? (strict ? `(-${relativeSeqNum})?` : '(-[0-9])?') : `-${relativeSeqNum}`;
        // getLayerNum function is only called by createDuplicateLayerRegex
        layerNum = numOfDuplicates == 1 ? strict ? "(-".concat(relativeSeqNum, ")?") : '(-[0-9])?' : '(-[0-9])?';
    }
    ;
    return layerNum;
};
var createLayerRegex = function (layers, securementPoint, securementType) {
    var refs = {};
    var name = 'a';
    layers.forEach(function (layer, index) { if (layer.isSecurementLayer) {
        var securementLayer = layer;
        if (securementLayer.fromLayerTypeId || securementLayer.toLayerTypeId)
            refs[index] = {};
        if (securementLayer.fromLayerTypeId && (securementPoint !== index || securementType !== 'from')) {
            var _securementLayer$from;
            var pos = findLayerPos(layers, securementLayer.fromLayerTypeId, (_securementLayer$from = securementLayer.fromLayerNum) !== null && _securementLayer$from !== void 0 ? _securementLayer$from : 1);
            if (pos > -1) {
                if (refs[pos] && refs[pos].backref) {
                    refs[index].fromLayer = { backref: refs[pos].backref };
                }
                else if (!refs[pos]) {
                    refs[pos] = { groupName: name };
                    name = String.fromCharCode(name.charCodeAt(0) + 1);
                    refs[index].fromLayer = { backref: refs[pos].groupName };
                }
                else
                    refs[index].fromLayer = { backref: refs[pos].groupName };
            }
        }
        if (securementLayer.toLayerTypeId && (securementPoint !== index || securementType !== 'to')) {
            var _securementLayer$toLa;
            var pos = findLayerPos(layers, securementLayer.toLayerTypeId, (_securementLayer$toLa = securementLayer.toLayerNum) !== null && _securementLayer$toLa !== void 0 ? _securementLayer$toLa : 1);
            if (pos > -1) {
                if (refs[pos] && refs[pos].groupName) {
                    refs[index].toLayer = { backref: refs[pos].groupName };
                }
                else if (refs[pos] && refs[pos].backref) {
                    refs[index].toLayer = { backref: refs[pos].backref };
                }
                else {
                    refs[index].toLayer = { groupName: name };
                    refs[pos] = { backref: name };
                    name = String.fromCharCode(name.charCodeAt(0) + 1);
                }
            }
        }
    } });
    var layerRegex = new RegExp("^.*".concat(layers.map(function (layer, index) {
        var temp = "L\\(?\\[?".concat(layer.id, "(-[0-9])?");
        if (refs[index] && refs[index].groupName)
            temp = "L\\(?\\[?".concat(layer.id, "(?<").concat(refs[index].groupName, ">-[0-9])?");
        if (refs[index] && refs[index].backref)
            temp = "L\\(?\\[?".concat(layer.id, "\\k<").concat(refs[index].backref, ">"); // If layer is generic securement, any securement layer is allowed
        if (layer.id === '45') {
            temp = temp.replace('45(-[0-9])?', "(".concat(securementPoint === index && securementType === 'layer' ? '?<s>' : '', "(39|41|42|44|45)(-[0-9])?)"));
        } // if (index !== 0 && !layers[index - 1].isSecurementLayer) temp = ',' + temp;
        if (!layer.isSecurementLayer) { // May be self securement
            temp += "(&".concat(layer.hasSelfSecurement ? layer.SelfSecurementId : '[0-9][0-9]', ")?"); // May be optional and may be permeable
            temp += '\\)?\\]?';
        }
        else {
            var securementLayer = layer;
            if (securementLayer.fromLayerTypeId || securementLayer.toLayerTypeId) {
                temp += "\\{";
                if (refs[index] && refs[index].fromLayer) {
                    if (refs[index].fromLayer.groupName)
                        temp += "".concat(numToString(securementLayer.fromLayerTypeId), "(?<").concat(refs[index].fromLayer.groupName, ">-[0-9])?");
                    if (refs[index].fromLayer.backref)
                        temp += "".concat(numToString(securementLayer.fromLayerTypeId), "\\k<").concat(refs[index].fromLayer.backref, ">");
                }
                else if (securementPoint === index && securementType === 'from')
                    temp += '(?<s>[0-9][0-9](-[0-9])?)?';
                else
                    temp += '[0-9][0-9](-[0-9])?';
                temp += ':';
                if (refs[index] && refs[index].toLayer) {
                    if (refs[index].toLayer.groupName)
                        temp += "".concat(numToString(securementLayer.toLayerTypeId), "(?<").concat(refs[index].toLayer.groupName, ">-[0-9])?");
                    if (refs[index].toLayer.backref)
                        temp += "".concat(numToString(securementLayer.toLayerTypeId), "\\k<").concat(refs[index].toLayer.backref, ">");
                }
                else if (securementPoint === index && securementType === 'to')
                    temp += '(?<s>[0-9][0-9](-[0-9])?)?';
                else
                    temp += '[0-9][0-9](-[0-9])?';
                temp += '\\}';
            }
            else if (securementPoint === index) {
                if (securementType === 'from')
                    temp += '\\{(?<s>[0-9][0-9](-[0-9])?):([0-9][0-9])(-[0-9])?\\}';
                else if (securementType === 'to')
                    temp += '\\{([0-9][0-9])(-[0-9])?:(?<s>[0-9][0-9](-[0-9])?)\\}';
                else
                    temp += '\\{[0-9][0-9](-[0-9])?:([0-9][0-9])?(-[0-9])?\\}';
            }
            else
                temp += '\\{[0-9][0-9](-[0-9])?:([0-9][0-9])?(-[0-9])?\\}';
        }
        return temp;
    }).join('.*,'), ".*$"));
    return layerRegex;
};
var createDuplicateLayerRegex = function (layers, securementPoint, securementType) {
    var strict = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    var numOfSecurements = 0;
    for (var i = 0; i < layers.length; i++) {
        if (layers[i].isSecurementLayer)
            numOfSecurements++;
    }
    var layerRegex = new RegExp("^.*".concat(layers.map(function (layer, index) {
        var layerNum = getLayerNum(layers, index, strict); // LayerTypeId and may be RepeatNum
        var temp = "L\\(?\\[?".concat(layer.id).concat(layerNum);
        if (layer.id === '45') {
            temp = temp.replace("45".concat(layerNum), "(".concat(securementPoint === index && securementType === 'layer' ? '?<s>' : '', "(39|40|41|42|43|44|45)").concat(layerNum, ")"));
        }
        if (!layer.isSecurementLayer) { // May be self securement
            temp += "(&".concat(layer.hasSelfSecurement ? layer.SelfSecurementId : '[0-9][0-9]', ")?"); // May be optional and may be permeable
            temp += '\\)?\\]?';
        }
        else {
            var securementLayer = layer;
            temp += "\\{";
            if (securementPoint === index && securementType === 'from')
                temp += '(?<s>[0-9][0-9](-[0-9])?)?';
            else if (securementLayer.fromLayerTypeId) {
                var _securementLayer$from2;
                temp += numToString(securementLayer.fromLayerTypeId);
                var pos = findLayerPos(layers, securementLayer.fromLayerTypeId, (_securementLayer$from2 = securementLayer.fromLayerNum) !== null && _securementLayer$from2 !== void 0 ? _securementLayer$from2 : 1);
                if (pos > -1) {
                    var securementNum = getLayerNum(layers, pos, strict);
                    temp += securementNum;
                }
            }
            else
                temp += '[0-9][0-9](-[0-9])?';
            temp += ':';
            if (securementPoint === index && securementType === 'to')
                temp += '(?<s>[0-9][0-9](-[0-9])?)?';
            else if (securementLayer.toLayerTypeId) {
                var _securementLayer$toLa2;
                temp += numToString(securementLayer.toLayerTypeId);
                var pos = findLayerPos(layers, securementLayer.toLayerTypeId, (_securementLayer$toLa2 = securementLayer.toLayerNum) !== null && _securementLayer$toLa2 !== void 0 ? _securementLayer$toLa2 : 1);
                if (pos > -1) {
                    var securementNum = getLayerNum(layers, pos, strict);
                    temp += securementNum;
                }
            }
            else
                temp += '[0-9][0-9](-[0-9])?';
            temp += "\\}";
        }
        return temp;
    }).join('.*,'), ".*$"));
    return layerRegex;
};
var createExcludedLayerRegex = function (excludedLayerIds) { return new RegExp("^.*(".concat(excludedLayerIds.join('|'), ").*$")); };
var isLayerConfigMatching = function (extentedLayerConfig, layerRegex, excludedLayerRegex) { var handleDuplicateLayers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false; var configString = handleDuplicateLayers ? extentedLayerConfig.GenericSecurementDuplicateString : extentedLayerConfig.FullConfigString; var hasLayers = layerRegex ? layerRegex.test(configString) : true; var hasExcludedLayers = excludedLayerRegex ? excludedLayerRegex.test(configString) : false; return hasLayers && !hasExcludedLayers; };
export var getMatchingLayerConfigs = function (layers, excludedLayerIds, extentedLayerConfigs) {
    var handleDuplicateLayers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    var strict = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false; // console.log('getMatchingLayerConfigs - starts');
    // console.time('getMatchingLayerConfigs');
    var layerRegex = layers.length ? createLayerRegex(layers) : undefined;
    if (handleDuplicateLayers)
        layerRegex = layers.length ? createDuplicateLayerRegex(layers, undefined, undefined, strict) : undefined;
    var excludedLayerRegex = excludedLayerIds.length ? createExcludedLayerRegex(excludedLayerIds) : undefined;
    var result = extentedLayerConfigs.filter(function (data) { return isLayerConfigMatching(data, layerRegex, excludedLayerRegex, handleDuplicateLayers); }); // console.timeEnd('getMatchingLayerConfigs');
    // console.log('getMatchingLayerConfigs - ends');
    return result;
};
var isLayerCakeValid = function (layers, excludedLayerIds, extentedLayerConfigs) { var layerRegex = layers.length ? createLayerRegex(layers) : undefined; var excludedLayerRegex = excludedLayerIds.length ? createExcludedLayerRegex(excludedLayerIds) : undefined; return !!extentedLayerConfigs.find(function (data) { return isLayerConfigMatching(data, layerRegex, excludedLayerRegex); }); };
export var getLayerInsertPoints = function (layers, excludedLayerIds, extentedLayerConfigs) {
    // console.time('getLayerInsertPoints');
    var insertPoint = { id: '[0-9][0-9]', autoSelected: false // isSecurementLayer: true,
    };
    var insertPoints = [];
    for (var i = 0; i < layers.length; i++) {
        var temp_1 = __spreadArray(__spreadArray(__spreadArray([], layers.slice(0, i), true), [insertPoint], false), layers.slice(i), true);
        if (isLayerCakeValid(temp_1, excludedLayerIds, extentedLayerConfigs))
            insertPoints.push(i);
    }
    var temp = __spreadArray(__spreadArray([], layers, true), [insertPoint], false);
    if (isLayerCakeValid(temp, excludedLayerIds, extentedLayerConfigs))
        insertPoints.push(layers.length); // console.timeEnd('getLayerInsertPoints');
    // console.log('getLayerInsertPoints - ends');
    return insertPoints;
};
export var getLayerInsertPointDomain = function (layers, excludedLayerIds, extentedLayerConfigs, insertPoint) {
    // console.time('getLayerInsertPointDomain');
    var domain = [];
    for (var i = 0; i < LAYER_DATA.length; i++) {
        var layerType = LAYER_DATA[i];
        var isSecurementLayer = layerType.id === '11118';
        for (var j = 0; j < layerType.layers.length; j++) {
            var layer = { id: String(layerType.layers[j].value), autoSelected: false, isSecurementLayer: isSecurementLayer };
            var temp = __spreadArray(__spreadArray(__spreadArray([], layers.slice(0, insertPoint), true), [layer], false), layers.slice(insertPoint), true);
            if (isLayerCakeValid(temp, excludedLayerIds, extentedLayerConfigs))
                domain.push(layer.id);
        }
    } // console.timeEnd('getLayerInsertPointDomain');
    // console.log('getLayerInsertPointDomain - ends');
    return domain;
};
var getSmallestExtendedLayerConfig = function (extentedLayerConfigs) { var result = extentedLayerConfigs[0]; var min = result.FullConfigString.split(',').length; for (var i = 1; i < extentedLayerConfigs.length; i++) {
    var temp = extentedLayerConfigs[i].FullConfigString.split(',').length;
    if (temp < min) {
        result = extentedLayerConfigs[i];
        min = temp;
    }
} return result; };
var buildLayerCake = function (extentedLayerConfig) {
    return extentedLayerConfig.FullConfigString.split(',').map(function (data) {
        var temp = data.replace('L', '').replace('(', '').replace(')', '').replace('[', '').replace(']', '').replace('}', '');
        if (temp.includes('{')) {
            var _parseInt, _parseInt2;
            var tempFull_1 = temp.split('{');
            var tempLayer = tempFull_1[0].split('&');
            var id_1 = tempLayer[0].split('-')[0];
            var SelfSecurementId_1 = tempLayer[1];
            var tempSecuremnt = tempFull_1[1].split(':');
            var tempFrom = tempSecuremnt[0].split('-');
            var fromLayerTypeId = parseInt(tempFrom[0]);
            var fromLayerNum = (_parseInt = parseInt(tempFrom[1])) !== null && _parseInt !== void 0 ? _parseInt : 1;
            var tempTo = tempSecuremnt[1].split('-');
            var toLayerTypeId = parseInt(tempTo[0]);
            var toLayerNum = (_parseInt2 = parseInt(tempTo[1])) !== null && _parseInt2 !== void 0 ? _parseInt2 : 1;
            var securmentLayer = { id: id_1, autoSelected: true, isSecurementLayer: true, // hasSelfSecurement: !!SelfSecurementId,
                // SelfSecurementId,
                fromLayerNum: isNaN(fromLayerNum) ? 1 : fromLayerNum, fromLayerTypeId: fromLayerTypeId, toLayerTypeId: toLayerTypeId, toLayerNum: isNaN(toLayerNum) ? 1 : toLayerNum };
            return securmentLayer;
        }
        var tempFull = temp.split('&');
        var id = tempFull[0].split('-')[0];
        var SelfSecurementId = tempFull[1];
        return { id: id, autoSelected: true // hasSelfSecurement: !!SelfSecurementId,
            // SelfSecurementId,
         };
    });
};
var isAllLayerConfigsMatching = function (layerRegex, extentedLayerConfigs) { for (var i = 0; i < extentedLayerConfigs.length; i++) {
    if (!layerRegex.test(extentedLayerConfigs[i].FullConfigString))
        return false;
} return true; };
var createExtendedLayerConfig = function (layers) { var FullConfigString = layers.map(function (layer) { return layer.isSecurementLayer ? "L".concat(layer.id, "{").concat(numToString(layer.fromLayerTypeId), ":").concat(numToString(layer.toLayerTypeId), "}") : "L".concat(layer.id); }).join(','); return { ExtendedLayerConfigId: -1, FullConfigString: FullConfigString }; }; // const removeSecurementFromTo = (layer: ILayer, removeType: 'from' | 'to' | 'both') => {
//   const securementLayer = { ...layer } as ISecurementLayer;
//   if (removeType === 'from' || removeType === 'both') {
//     securementLayer.fromLayerTypeId = undefined;
//     securementLayer.fromLayerNum = undefined;
//   }
//   if (removeType === 'to' || removeType === 'both') {
//     securementLayer.toLayerTypeId = undefined;
//     securementLayer.toLayerNum = undefined;
//   }
//   return securementLayer;
// };
// const pushUnique = (queue: ILayer[][], layers: ILayer[]) => {
//   const alreadyAdded = queue.find(
//     (data) => createExtendedLayerConfig(data).FullConfigString === createExtendedLayerConfig(layers).FullConfigString
//   );
//   if (!alreadyAdded) queue.push(layers);
// };
// const pushSampleSecurementLayers = (queue: ILayer[][], curSampleLayers: ILayer[], securementPoint: number) => {
//   let nextSampleLayers = curSampleLayers.map((layer, index) => {
//     if (index === securementPoint) return removeSecurementFromTo(layer, 'from');
//     return layer;
//   });
//   pushUnique(queue, nextSampleLayers);
//   nextSampleLayers = curSampleLayers.map((layer, index) => {
//     if (index === securementPoint) return removeSecurementFromTo(layer, 'to');
//     return layer;
//   });
//   pushUnique(queue, nextSampleLayers);
//   nextSampleLayers = curSampleLayers.map((layer, index) => {
//     if (index === securementPoint) return removeSecurementFromTo(layer, 'both');
//     return layer;
//   });
//   pushUnique(queue, nextSampleLayers);
// };
// const getInferredLayersBFS = (
//   sampleLayers: ILayer[],
//   extentedLayerConfigs: IExtendedLayerConfig[],
//   layerRegex?: RegExp
// ): ILayer[] => {
//   let level = sampleLayers.length;
//   const queue: ILayer[][] = [];
//   queue.push(sampleLayers);
//   let autoSelectedLayers: any = {};
//   while (queue.length) {
//     const curSampleLayers = queue.shift();
//     if (curSampleLayers?.length !== undefined) {
//       if (curSampleLayers.length < level) {
//         const autoSelectedLayerKeys = Object.keys(autoSelectedLayers);
//         if (autoSelectedLayerKeys.length === 1) return autoSelectedLayers[autoSelectedLayerKeys[0]] as ILayer[];
//         // if (autoSelectedLayerKeys.length > 1) {
//         //   return getInferredLayersBFS(
//         //     autoSelectedLayers[autoSelectedLayerKeys[0]],
//         //     autoSelectedLayerKeys.map((data) => autoSelectedLayers[data].join(',')),
//         //     layerRegex
//         //   );
//         // }
//         autoSelectedLayers = {};
//         level = curSampleLayers.length;
//         if (level === 0) return [];
//       }
//       const sampleExtendedLayerConfig = createExtendedLayerConfig(curSampleLayers);
//       let match = true;
//       if (layerRegex && !isAllLayerConfigsMatching(layerRegex, [sampleExtendedLayerConfig])) match = false;
//       else {
//         const sampleLayersRegex = createLayerRegex(curSampleLayers);
//         if (!isAllLayerConfigsMatching(sampleLayersRegex, extentedLayerConfigs)) match = false;
//       }
//       if (match) return curSampleLayers;
//       // if (match) autoSelectedLayers[sampleExtendedLayerConfig.FullConfigString] = curSampleLayers;
//       for (let i = 0; i < curSampleLayers.length; i++) {
//         const layerToBeRemoved = curSampleLayers[i];
//         if (layerToBeRemoved.isSecurementLayer) pushSampleSecurementLayers(queue, curSampleLayers, i);
//         const nextSampleLayers = curSampleLayers.filter((layer, index) => index !== i);
//         pushUnique(queue, nextSampleLayers);
//       }
//     }
//   }
//   return [];
// };
// const getInferredLayersBottomUpUtil = (
//   sampleLayers: ILayer[],
//   extentedLayerConfigs: IExtendedLayerConfig[],
//   solutions: ILayer[][]
// ) => {
//   const newSolutions: ILayer[][] = [];
//   solutions.forEach((solution) => {
//     let i = 0;
//     solution.forEach((layer) => {
//       while (i < sampleLayers.length && sampleLayers[i].id !== layer.id) i++;
//     });
//     for (i = i + 1; i < sampleLayers.length; i++) {
//       const newSolution = [...solution, sampleLayers[i]];
//       const newSolutionRegex = createLayerRegex(newSolution);
//       if (!isAllLayerConfigsMatching(newSolutionRegex, extentedLayerConfigs)) newSolutions.push(newSolution);
//     }
//   });
//   return newSolutions;
// };
// const getInferredLayersBottomUp = (
//   sampleLayers: ILayer[],
//   extentedLayerConfigs: IExtendedLayerConfig[],
//   layers: ILayer[]
// ) => {
//   const solutions: ILayer[][] = [];
//   if (layers.length) solutions.push(layers);
//   else {
//     for (let i = 0; i < sampleLayers.length; i++) {
//       solutions.push([sampleLayers[i]]);
//     }
//   }
//   while (solutions.length > 1) {
//     let newSolutions = getInferredLayersBottomUpUtil(sampleLayers, extentedLayerConfigs, solutions);
//     if (!newSolutions.length) newSolutions = [solutions[0]];
//   }
//   return solutions[0];
// };
var getInferredLayersGreedyFindNextSolution = function (sampleLayer, extentedLayerConfigs, solution, layerRegex) { for (var i = 0; i <= solution.length; i++) {
    var newSolution = __spreadArray(__spreadArray(__spreadArray([], solution.slice(0, i), true), [sampleLayer], false), solution.slice(i), true);
    var relSeqNum = 1;
    for (var j = 0; j < i; j++) {
        if (solution[j].id === sampleLayer.id)
            relSeqNum++;
    }
    for (var j = i + 1; j < newSolution.length; j++) {
        if (newSolution[j].isSecurementLayer) {
            var securmentLayer = _objectSpread({}, newSolution[j]);
            if (securmentLayer.fromLayerTypeId === Number(sampleLayer.id) && securmentLayer.fromLayerNum && securmentLayer.fromLayerNum >= relSeqNum) {
                securmentLayer.fromLayerNum++;
                newSolution[j] = securmentLayer;
            }
            if (securmentLayer.toLayerTypeId === Number(sampleLayer.id) && securmentLayer.toLayerNum && securmentLayer.toLayerNum >= relSeqNum) {
                securmentLayer.toLayerNum++;
                newSolution[j] = securmentLayer;
            }
        }
    }
    var newSolutionExtendedLayerConfig = createExtendedLayerConfig(newSolution);
    if (!layerRegex || isAllLayerConfigsMatching(layerRegex, [newSolutionExtendedLayerConfig])) {
        var newSolutionRegex = createLayerRegex(newSolution);
        if (isAllLayerConfigsMatching(newSolutionRegex, extentedLayerConfigs))
            return newSolution;
    }
} };
var getInferredLayersGreedyUtil = function (sampleLayers, extentedLayerConfigs, solution, layerRegex) { for (var i = 0; i < sampleLayers.length; i++) {
    var newSolution = getInferredLayersGreedyFindNextSolution(sampleLayers[i], extentedLayerConfigs, solution, layerRegex);
    if (newSolution)
        return newSolution;
    if (sampleLayers[i].isSecurementLayer) {
        var securementLayer = _objectSpread({}, sampleLayers[i]);
        newSolution = getInferredLayersGreedyFindNextSolution(_objectSpread(_objectSpread({}, securementLayer), {}, { fromLayerNum: undefined, fromLayerTypeId: undefined }), extentedLayerConfigs, solution, layerRegex);
        if (newSolution)
            return newSolution;
        newSolution = getInferredLayersGreedyFindNextSolution(_objectSpread(_objectSpread({}, securementLayer), {}, { toLayerNum: undefined, toLayerTypeId: undefined }), extentedLayerConfigs, solution, layerRegex);
        if (newSolution)
            return newSolution;
        newSolution = getInferredLayersGreedyFindNextSolution(_objectSpread(_objectSpread({}, securementLayer), {}, { fromLayerNum: undefined, fromLayerTypeId: undefined, toLayerNum: undefined, toLayerTypeId: undefined }), extentedLayerConfigs, solution, layerRegex);
        if (newSolution)
            return newSolution;
    }
} };
var getInferredLayersGreedy = function (sampleLayers, extentedLayerConfigs, layers) { var solution = layers; var layerRegex = undefined; if (layers.find(function (layer) { return layer.isSecurementLayer; })) {
    layerRegex = createLayerRegex(layers);
} while (solution) {
    var newSolution = getInferredLayersGreedyUtil(sampleLayers, extentedLayerConfigs, solution, layerRegex);
    if (newSolution)
        solution = newSolution;
    else {
        for (var i = 0; i < solution.length; i++) {
            if (solution[i].isSecurementLayer) {
                var securementLayer = solution[i];
                if (securementLayer.fromLayerTypeId) {
                    var _securementLayer$from3;
                    var layerNum = (_securementLayer$from3 = securementLayer.fromLayerNum) !== null && _securementLayer$from3 !== void 0 ? _securementLayer$from3 : 1;
                    for (var k = 0; k < i && layerNum > 0; k++) {
                        if (solution[k].id === numToString(securementLayer.fromLayerTypeId))
                            layerNum--;
                    }
                    if (layerNum !== 0) {
                        securementLayer.fromLayerNum = undefined;
                        securementLayer.fromLayerTypeId = undefined;
                    }
                }
                if (securementLayer.toLayerTypeId) {
                    var _securementLayer$toLa3;
                    var layerNum = (_securementLayer$toLa3 = securementLayer.toLayerNum) !== null && _securementLayer$toLa3 !== void 0 ? _securementLayer$toLa3 : 1;
                    for (var k = i + 1; k < solution.length && layerNum > 0; k++) {
                        if (solution[k].id === numToString(securementLayer.toLayerTypeId))
                            layerNum--;
                    }
                    if (layerNum !== 0) {
                        securementLayer.toLayerNum = undefined;
                        securementLayer.toLayerTypeId = undefined;
                    }
                }
            }
        }
        return solution;
    }
} return solution; };
var isSecurementUpdateValid = function (sampleLayers, securementLayer, i, layerRegex) { if (!layerRegex)
    return true; var nextSampleLayers = sampleLayers.map(function (layer, index) { if (index === i)
    return securementLayer; return layer; }); var sampleExtendedLayerConfig = createExtendedLayerConfig(nextSampleLayers); return isAllLayerConfigsMatching(layerRegex, [sampleExtendedLayerConfig]); };
var removeSampleSecurements = function (sampleLayers, layerRegex) { for (var i = 0; i < sampleLayers.length; i++) {
    var layer = sampleLayers[i];
    if (layer.isSecurementLayer) {
        var securementLayer = layer;
        if (securementLayer.fromLayerTypeId) {
            securementLayer = _objectSpread(_objectSpread({}, layer), {}, { fromLayerNum: undefined, fromLayerTypeId: undefined });
            if (isSecurementUpdateValid(sampleLayers, securementLayer, i, layerRegex)) {
                securementLayer = layer;
                securementLayer.fromLayerNum = undefined;
                securementLayer.fromLayerTypeId = undefined;
            }
        }
        if (securementLayer.toLayerTypeId) {
            securementLayer = _objectSpread(_objectSpread({}, layer), {}, { toLayerNum: undefined, toLayerTypeId: undefined });
            if (isSecurementUpdateValid(sampleLayers, securementLayer, i, layerRegex)) {
                securementLayer = layer;
                securementLayer.toLayerNum = undefined;
                securementLayer.toLayerTypeId = undefined;
            }
        }
    }
} };
var removeUnnecessaryLayers = function (sampleLayers, extentedLayerConfigs) { return sampleLayers.filter(function (layer) { return !extentedLayerConfigs.find(function (extentedLayerConfig) { return !(extentedLayerConfig.FullConfigString.includes("L".concat(layer.id)) || extentedLayerConfig.FullConfigString.includes("L[".concat(layer.id)) || extentedLayerConfig.FullConfigString.includes("L(".concat(layer.id))); }); }); };
var compareExtentedLayerConfigs = function (a, b) { return a.FullConfigString.split(',').length - b.FullConfigString.split(',').length; };
export var getInferredLayers = function (layers, extentedLayerConfigs) {
    // console.time('getInferredLayers');
    if (!extentedLayerConfigs.length)
        return layers; // const layerRegex = layers.length ? createLayerRegex(layers) : undefined;
    extentedLayerConfigs = extentedLayerConfigs.sort(compareExtentedLayerConfigs);
    var sampleLayers = buildLayerCake(getSmallestExtendedLayerConfig(extentedLayerConfigs));
    sampleLayers = removeUnnecessaryLayers(sampleLayers, extentedLayerConfigs); // removeSampleSecurements(sampleLayers, layerRegex);
    // console.time('getInferredLayersBFS');
    // const inferredLayers = getInferredLayersBFS(sampleLayers, extentedLayerConfigs, layerRegex);
    // console.log(inferredLayers);
    // console.timeEnd('getInferredLayersBFS');
    var inferredLayers = getInferredLayersGreedy(sampleLayers, extentedLayerConfigs, layers); // console.log(inferredLayers);
    if (!inferredLayers.length)
        return layers; // const result = updateSecurementTypes(inferredLayers);
    // console.timeEnd('getInferredLayers');
    // console.log('getInferredLayers - ends');
    // const processedLayers = processInferredSecurement(inferredLayers);
    // const processedLayers = inferredLayers.filter((layer) => !(layer.autoSelected && layer.isSecurementLayer));
    var hasEditedSecurementLayer = false;
    for (var i = 0; i < layers.length; i++) {
        if (layers[i].isSecurementLayer) {
            var _layers$i, _layers$i$variableAss, _layers$i$variableAss2;
            var allowedValues = (_layers$i = layers[i]) === null || _layers$i === void 0 ? void 0 : (_layers$i$variableAss = _layers$i.variableAssignments) === null || _layers$i$variableAss === void 0 ? void 0 : (_layers$i$variableAss2 = _layers$i$variableAss.find(function (data) { return data.variableId === 'ComponentId_view'; })) === null || _layers$i$variableAss2 === void 0 ? void 0 : _layers$i$variableAss2.allowedValues;
            if (allowedValues) {
                hasEditedSecurementLayer = true;
                break;
            }
            ;
        }
    }
    var processedLayers;
    if (hasEditedSecurementLayer) { // search layer cake has edited securement layers: no inferred layers
        processedLayers = inferredLayers.filter(function (layer) { return !layer.autoSelected; });
    }
    else { // search layer cake has no edited securement layers: allow inferred component layers, but no inferred securement layer
        processedLayers = inferredLayers.filter(function (layer) { return !(layer.autoSelected && layer.isSecurementLayer); });
    } //const processedLayers = inferredLayers.filter((layer) => !(layer.autoSelected));
    return processedLayers;
};
var processInferredSecurement = function (inferredLayers) {
    if (inferredLayers.find(function (layer) { return layer.autoSelected && layer.isSecurementLayer; }) === undefined) {
        return inferredLayers;
    } // Inferred securement exists. Need further check / process
    // Correct inferred specific securement which has no from info
    inferredLayers = correctInferredProblematicSpecificSecurement(inferredLayers); // If any securement layer is configured, the auto inferred dummy securement layers may be removed
    inferredLayers = removeInferredDummySecurementLayer(inferredLayers);
    return inferredLayers;
};
var correctInferredProblematicSpecificSecurement = function (inferredLayers) {
    if (inferredLayers.find(function (layer) { return layer.autoSelected && layer.isSecurementLayer && layer.id !== '45'; })) { // Search cake has auto added specific securement layer
        for (var i = 0; i < inferredLayers.length; i++) {
            if (inferredLayers[i].autoSelected && inferredLayers[i].isSecurementLayer && inferredLayers[i].id !== '45') {
                var securementLayer = inferredLayers[i];
                if (securementLayer.fromLayerNum === undefined || securementLayer.fromLayerTypeId === undefined) {
                    securementLayer.id = '45';
                }
            }
        }
    }
    return inferredLayers;
};
var removeInferredDummySecurementLayer = function (inferredLayers) {
    if (inferredLayers.find(function (layer) { return layer.autoSelected === false && layer.isSecurementLayer; }) === undefined) {
        return inferredLayers;
    } // If any securement layer is configured, the auto inferred dummy securement layers (general securement type, no from/to info) may be removed
    if (inferredLayers.find(function (layer) { var _layer$variableAssign; return layer.autoSelected === false && layer.isSecurementLayer && ((_layer$variableAssign = layer.variableAssignments) === null || _layer$variableAssign === void 0 ? void 0 : _layer$variableAssign.length); })) {
        return inferredLayers.filter(function (layer) { if (layer.autoSelected && layer.id === '45' && layer.isSecurementLayer) {
            var securementLayer = layer;
            return securementLayer.fromLayerNum || securementLayer.toLayerNum ? true : false;
        }
        else
            return true; });
    }
    else
        return inferredLayers;
};
var updateSecurementTypes = function (layers) { return layers.map(function (layer) { if (layer.isSecurementLayer) {
    var _securementLayer$from4, _securementLayer$toLa4;
    var securementLayer = _objectSpread({}, layer);
    securementLayer.id = DetermineStandardSecurementLayerType((_securementLayer$from4 = securementLayer.fromLayerTypeId) !== null && _securementLayer$from4 !== void 0 ? _securementLayer$from4 : 0, (_securementLayer$toLa4 = securementLayer.toLayerTypeId) !== null && _securementLayer$toLa4 !== void 0 ? _securementLayer$toLa4 : 0).toString();
    return securementLayer;
} return layer; }); };
export var getSecurementDomain = function (layers, extentedLayerConfigs, securementPoint, securementType) {
    // console.time('getSecurementDomain');
    var layerRegex = createDuplicateLayerRegex(layers, securementPoint, securementType, false);
    var result = [];
    for (var i = 0; i < extentedLayerConfigs.length; i++) {
        var _layerRegex$exec, _layerRegex$exec$grou;
        var GenericSecurementDuplicateString = extentedLayerConfigs[i].GenericSecurementDuplicateString;
        var match = (_layerRegex$exec = layerRegex.exec(GenericSecurementDuplicateString)) === null || _layerRegex$exec === void 0 ? void 0 : (_layerRegex$exec$grou = _layerRegex$exec.groups) === null || _layerRegex$exec$grou === void 0 ? void 0 : _layerRegex$exec$grou.s;
        var _loop_1 = function () {
            var removeMatchPattern = securementType === 'from' ? "{".concat(match, ":[0-9][0-9](-[0-9])?}") : "{[0-9][0-9](-[0-9])?:".concat(match, "}");
            var temp = match.split('-');
            var layerTypeId = parseInt(temp[0]);
            if (!temp[1]) {
                match += '-1';
            }
            var layerNum = temp[1] ? parseInt(temp[1]) : 1;
            var pos = findLayerPos(layers, layerTypeId, layerNum);
            var isValidFromTo = false;
            if (pos > -1) { // This matching from/to layer type with layer number is valid based on current layer cake
                // Further check if this matching from/to layer is valid based on its relative position against securement from/to point
                if (securementType === 'from' && pos < securementPoint) {
                    isValidFromTo = true;
                }
                if (securementType === 'to' && pos > securementPoint) {
                    isValidFromTo = true;
                }
            }
            if (isValidFromTo && !result.includes(match)) {
                var newLayerRegex = createDuplicateLayerRegex(layers.map(function (data, index) { if (index === securementPoint) {
                    var temp_2 = _objectSpread({}, data);
                    if (securementType === 'from') {
                        temp_2.fromLayerNum = layerNum;
                        temp_2.fromLayerTypeId = layerTypeId;
                    }
                    if (securementType === 'to') {
                        temp_2.toLayerNum = layerNum;
                        temp_2.toLayerTypeId = layerTypeId;
                    }
                    return temp_2;
                } return data; }), undefined, undefined, false);
                if (newLayerRegex.test(GenericSecurementDuplicateString))
                    result.push(match);
            }
            GenericSecurementDuplicateString = GenericSecurementDuplicateString.replace(new RegExp(removeMatchPattern, 'g'), '');
            match = (_layerRegex$exec2 = layerRegex.exec(GenericSecurementDuplicateString)) === null || _layerRegex$exec2 === void 0 ? void 0 : (_layerRegex$exec2$gro = _layerRegex$exec2.groups) === null || _layerRegex$exec2$gro === void 0 ? void 0 : _layerRegex$exec2$gro.s;
        };
        var _layerRegex$exec2, _layerRegex$exec2$gro;
        while (match) {
            _loop_1();
        }
    } // console.timeEnd('getSecurementDomain');
    // console.log('getSecurementDomain - ends');
    return result;
};
export var getSpecificSecurementType = function (layers, extentedLayerConfigs, securementPoint) {
    // const id = DetermineStandardSecurementLayerType(
    //   securementLayer.fromLayerTypeId ?? 0,
    //   securementLayer.toLayerTypeId ?? 0
    // ).toString();
    // if (id !== securementLayer.id) return id;
    var layerRegex = createLayerRegex(layers.map(function (data, index) { if (index !== securementPoint)
        return data; return _objectSpread(_objectSpread({}, data), {}, { id: '45' }); }), securementPoint, 'layer');
    var result = [];
    for (var i = 0; i < extentedLayerConfigs.length; i++) {
        var _layerRegex$exec3, _layerRegex$exec3$gro;
        var FullConfigString = extentedLayerConfigs[i].FullConfigString;
        var match = (_layerRegex$exec3 = layerRegex.exec(FullConfigString)) === null || _layerRegex$exec3 === void 0 ? void 0 : (_layerRegex$exec3$gro = _layerRegex$exec3.groups) === null || _layerRegex$exec3$gro === void 0 ? void 0 : _layerRegex$exec3$gro.s;
        while (match) {
            var _layerRegex$exec4, _layerRegex$exec4$gro;
            var removeMatchPattern = "L\\(?\\[?".concat(match, "\\]?\\)?{[0-9][0-9](-[0-9])?:[0-9][0-9](-[0-9])?}");
            var temp = match.split('-');
            var layerTypeId = parseInt(temp[0]);
            if (!result.includes(layerTypeId))
                result.push(layerTypeId);
            FullConfigString = FullConfigString.replace(new RegExp(removeMatchPattern, 'g'), '');
            match = (_layerRegex$exec4 = layerRegex.exec(FullConfigString)) === null || _layerRegex$exec4 === void 0 ? void 0 : (_layerRegex$exec4$gro = _layerRegex$exec4.groups) === null || _layerRegex$exec4$gro === void 0 ? void 0 : _layerRegex$exec4$gro.s;
        }
    }
    if (!result.length)
        return layers[securementPoint].id;
    if (result.length === 1)
        return result[0].toString();
    return '45';
};
export var getExcludedLayerDomain = function (extentedLayerConfigs, excludedLayers) {
    // console.time('getExcludedLayerDomain');
    var layerData = getFlatLayerData().filter(function (layer) { return layer.value !== '51' && layer.value !== '40' && layer.value !== '43'; });
    var result = layerData.map(function (layer) { var canExclude = false; var canInclude = false; for (var i = 0; i < extentedLayerConfigs.length; i++) {
        var FullConfigString = extentedLayerConfigs[i].FullConfigString;
        if (!(FullConfigString.includes("L".concat(layer.value)) || FullConfigString.includes("L[".concat(layer.value)) || FullConfigString.includes("L(".concat(layer.value))))
            canExclude = true;
        else
            canInclude = true;
        if (canExclude && canInclude)
            break;
    } layer.incompatible = !(canExclude && canInclude); layer.assigned = excludedLayers.indexOf(String(layer.value)) > -1 ? 'byUser' : undefined; return layer; }); // console.timeEnd('getExcludedLayerDomain');
    // console.log('getExcludedLayerDomain - ends');
    return result;
};
export var checkIfLayerCanContainSelfSecurement = function (layers, excludedLayerIds, extentedLayerConfigs, targetLayerIndex) {
    if (layers[targetLayerIndex].isSecurementLayer) { // Only component layer may have self-securement. Regular securement layer can not have self-securement
        return false;
    }
    if (layers[targetLayerIndex].hasSelfSecurement && layers[targetLayerIndex].SelfSecurementId) { // the target layer has set self-securement already
        return true;
    } // the target layer is a component layer and self-securement has not been set
    var temp = __spreadArray([], layers, true);
    temp[targetLayerIndex].hasSelfSecurement = true;
    temp[targetLayerIndex].SelfSecurementId = DetermineSelfSecurementLayerType(parseInt(temp[targetLayerIndex].id)).toString();
    return isLayerCakeValid(temp, excludedLayerIds, extentedLayerConfigs) ? true : false;
};
