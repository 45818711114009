var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useEffect, useState } from 'react';
import { fetchFreshToken } from '../AuthHelper';
import { CONFIGIT_MAP } from '../components/constants/CONFIGIT_MAP';
import { getLayerProps } from '../components/constants/LAYER_DATA';
import * as LayerApi from '../utils/LayerApi';
import { getLayerDataById } from '../utils/LayerTypeCheckUtilities';
import { ConfigitCacheManager } from './CacheService';
import { useLogger } from 'fmg-telemetry-react';
export var configure = function (variableAssignments) { return __awaiter(void 0, void 0, void 0, function () { var variableAssignmentsStr, response, data, sections, result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0:
            variableAssignmentsStr = ConfigitCacheManager.getVariableAssignmentsStr(variableAssignments);
            response = ConfigitCacheManager.getApiResponse('RoofNavModel', variableAssignmentsStr);
            if (!response) return [3 /*break*/, 2];
            if (response.data)
                return [2 /*return*/, response.data];
            if (!response.loading) return [3 /*break*/, 2];
            return [4 /*yield*/, ConfigitCacheManager.getApiResponsePromise('RoofNavModel', variableAssignmentsStr)];
        case 1: return [2 /*return*/, _a.sent()];
        case 2:
            ConfigitCacheManager.startApiCall('RoofNavModel', variableAssignmentsStr);
            return [4 /*yield*/, request(variableAssignments, 'RoofNavModel', 'RoofNavView')];
        case 3:
            data = (_a.sent()).sections;
            sections = data.map(function (section) { return reOrderValues(section); });
            result = mapWithDatabase(sections);
            ConfigitCacheManager.finishApiCall('RoofNavModel', variableAssignmentsStr, result);
            return [2 /*return*/, result];
    }
}); }); };
export var useConfigure = function (variableAssignments) { var logger = useLogger(); var _a = useState(), data = _a[0], setData = _a[1]; var _b = useState(false), loading = _b[0], setLoading = _b[1]; var _c = useState(), error = _c[0], setError = _c[1]; var _d = useState(), backup = _d[0], setBackup = _d[1]; if (backup && JSON.stringify(backup) !== JSON.stringify(variableAssignments)) {
    setData(undefined);
    setLoading(false);
    setError(undefined);
    setBackup(undefined);
}
else if (!loading) {
    configure(variableAssignments).then(function (sections) { setData(sections); setBackup(__spreadArray([], variableAssignments, true)); }, function (reason) { setError(reason); logger.error('Error in useConfigure', { error: error, msg: error.message }); }).catch(function (reason) { setError(reason); });
    setLoading(true);
} return { data: data, loading: !data && !error, error: error }; };
export var configureLayer = function (variableAssignments, model) { return __awaiter(void 0, void 0, void 0, function () { var variableAssignmentsStr, response, data, section, result; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0:
            variableAssignmentsStr = ConfigitCacheManager.getVariableAssignmentsStr(variableAssignments);
            response = ConfigitCacheManager.getApiResponse(model, variableAssignmentsStr);
            if (!response) return [3 /*break*/, 2];
            if (response.data)
                return [2 /*return*/, response.data];
            if (!response.loading) return [3 /*break*/, 2];
            return [4 /*yield*/, ConfigitCacheManager.getApiResponsePromise(model, variableAssignmentsStr)];
        case 1: return [2 /*return*/, _a.sent()];
        case 2:
            ConfigitCacheManager.startApiCall(model, variableAssignmentsStr);
            return [4 /*yield*/, request(variableAssignments, model)];
        case 3:
            data = _a.sent();
            section = sortDecimalValues(data.sections[0]);
            result = reOrderValues(section);
            ConfigitCacheManager.finishApiCall(model, variableAssignmentsStr, result);
            return [2 /*return*/, result];
    }
}); }); };
export var useConfigureLayer = function (variableAssignments, model) {
    var logger = useLogger();
    var _a = useState(), data = _a[0], setData = _a[1];
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState(), error = _c[0], setError = _c[1];
    useEffect(function () {
        var isSubscribed = false;
        if (model && variableAssignments) {
            configureLayer(variableAssignments, model).then(function (section) { if (!isSubscribed) {
                setData(section);
            } setLoading(false); }, function (reason) { setError(reason); setLoading(false); }).catch(function (reason) { setError(reason); setLoading(false); logger.error('Error in useConfigureLayer', { error: error, msg: error.message }); }); //console.log(JSON.stringify(variableAssignments));
            setLoading(true);
        }
        return function () { isSubscribed = true; }; // }
    }, [model, JSON.stringify(variableAssignments)]);
    return { data: data, loading: loading, error: error };
};
var computeVariableAssignment = function (variableId, compatibleValues, variables) { var _variables$find; var allowedValues = variables === null || variables === void 0 ? void 0 : (_variables$find = variables.find(function (obj) { return obj.id === variableId; })) === null || _variables$find === void 0 ? void 0 : _variables$find.values; return { variableId: variableId, type: 'AllowedValues', allowedValues: allowedValues ? allowedValues.filter(function (data) { return String(data.value) !== '0' && (compatibleValues === null || compatibleValues === void 0 ? void 0 : compatibleValues.includes(String(data.value))); }) : compatibleValues === null || compatibleValues === void 0 ? void 0 : compatibleValues.map(function (data) { return { type: 'SingletonValue', value: data }; }) }; };
export var getInitialLayerState = function (variableAssignments, allExtendedLayerConfigs, excludedLayerIds, layers, layerIndex, model, prevAllowedExtendedLayerConfigs) { return __awaiter(void 0, void 0, void 0, function () {
    var _layer$variableAssign3, exchangeModelVariableAssignments, securementRelativeSeqNum, curSecurementRelativeSeqNum, numOfDuplicatesVA, layer, layerData, layerProps, relativeSeqNum, numOfDuplicates, i, i, numOfDuplicatesVA, sections, section, variables, newVariableAssignments, compatibleExtendedLayerConfigs, matchingExtendedLayerConfigs, variableId, compatibleValues, securementEndSeqNum, combinedSecurementCompatibleValues, i, _getVariable, _getVariable$values, _getVariable$values$f, tempVariableId, singleSecurementCompatibleValues, _getVariable2, _getVariable2$values, _getVariable2$values$, layerVariableAssignments, defaultOperatorVariableAssignments;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                exchangeModelVariableAssignments = __spreadArray([], variableAssignments.filter(function (data) { return data.value; }), true);
                securementRelativeSeqNum = 0, curSecurementRelativeSeqNum = 0;
                layers.forEach(function (layer, index) { var _layer$variableAssign, _layer$variableAssign2; if (layer.isSecurementLayer)
                    securementRelativeSeqNum++; if (layerIndex === index) {
                    if (layer.isSecurementLayer)
                        curSecurementRelativeSeqNum = securementRelativeSeqNum;
                    return;
                } var allowedValues = layer === null || layer === void 0 ? void 0 : (_layer$variableAssign = layer.variableAssignments) === null || _layer$variableAssign === void 0 ? void 0 : (_layer$variableAssign2 = _layer$variableAssign.find(function (data) { return data.variableId === 'ComponentId_view'; })) === null || _layer$variableAssign2 === void 0 ? void 0 : _layer$variableAssign2.allowedValues; if (allowedValues) {
                    var layerData_1 = getLayerDataById(layer.id);
                    var layerProps_1 = getLayerProps(layerData_1);
                    var relativeSeqNum_1 = 1, numOfDuplicates_1 = 0;
                    var variableId_1 = "ComponentId_".concat(layer.model);
                    if (layerProps_1.hasDuplicates) {
                        if (!layer.isSecurementLayer) {
                            for (var i = 0; i < layers.length; i++) {
                                if (i === index)
                                    break;
                                if (layers[i].id === layer.id)
                                    relativeSeqNum_1++;
                            }
                            for (var i = 0; i < layers.length; i++) {
                                if (layers[i].id === layer.id)
                                    numOfDuplicates_1++;
                            }
                            variableId_1 = "ComponentId_".concat(layer.model).concat(relativeSeqNum_1);
                            var numOfDuplicatesVA = { variableId: "NumOfDuplicates_".concat(layer.model), value: String(numOfDuplicates_1) };
                            exchangeModelVariableAssignments.push(numOfDuplicatesVA);
                        }
                        else {
                            variableId_1 = "ComponentId_GenericSecurement".concat(securementRelativeSeqNum);
                            exchangeModelVariableAssignments.push({ variableId: "LayerTypeId_GenericSecurement".concat(relativeSeqNum_1), value: layer.id });
                        }
                    }
                    exchangeModelVariableAssignments.push({ variableId: variableId_1, type: 'AllowedValues', allowedValues: allowedValues });
                } });
                if (securementRelativeSeqNum) {
                    numOfDuplicatesVA = { variableId: "NumOfDuplicates_GenericSecurement_view", value: String(securementRelativeSeqNum) };
                    exchangeModelVariableAssignments.push(numOfDuplicatesVA);
                    exchangeModelVariableAssignments.push({ variableId: "NumOfDuplicates_GenericSecurementOperator", value: 'BiggerOrEqual' });
                }
                layer = layers[layerIndex];
                layerData = getLayerDataById(layer.id);
                layerProps = getLayerProps(layerData);
                relativeSeqNum = 1, numOfDuplicates = 0;
                if (layerProps.hasDuplicates && !layer.isSecurementLayer) {
                    for (i = 0; i < layers.length; i++) {
                        if (i === layerIndex)
                            break;
                        if (layers[i].id === layer.id)
                            relativeSeqNum++;
                    }
                    for (i = 0; i < layers.length; i++) {
                        if (layers[i].id === layer.id)
                            numOfDuplicates++;
                    }
                    numOfDuplicatesVA = { variableId: "NumOfDuplicates_".concat(model), value: String(numOfDuplicates) };
                    exchangeModelVariableAssignments.push(numOfDuplicatesVA);
                }
                if (prevAllowedExtendedLayerConfigs && prevAllowedExtendedLayerConfigs.length > 0) {
                    exchangeModelVariableAssignments.push({ variableId: 'ExtendedLayerConfigSplit_ExtendedLayerConfigId', type: 'AllowedValues', allowedValues: prevAllowedExtendedLayerConfigs.map(function (data) { return { type: 'SingletonValue', value: String(data.value) }; }) });
                }
                return [4 /*yield*/, configure(exchangeModelVariableAssignments)];
            case 1:
                sections = _a.sent();
                return [4 /*yield*/, configureLayer([], model)];
            case 2:
                section = _a.sent();
                variables = section.variables;
                newVariableAssignments = [];
                compatibleExtendedLayerConfigs = getCompatibleExtendedLayerConfigs(sections, allExtendedLayerConfigs);
                matchingExtendedLayerConfigs = LayerApi.getMatchingLayerConfigs(layers, excludedLayerIds, compatibleExtendedLayerConfigs);
                newVariableAssignments.push({ variableId: 'ExtendedLayerConfigSplit_ExtendedLayerConfigId', type: 'AllowedValues', allowedValues: matchingExtendedLayerConfigs.map(function (data) { return { type: 'SingletonValue', value: String(data.ExtendedLayerConfigId) }; }) }); // Products allowed
                variableId = "ComponentId_".concat(model).concat(layerProps.hasDuplicates ? relativeSeqNum : '');
                if (layer.isSecurementLayer)
                    variableId = "ComponentId_GenericSecurement".concat(curSecurementRelativeSeqNum); // const compatibleValues = getVariable(sections, variableId)
                if (layer.isSecurementLayer) {
                    securementEndSeqNum = 9 - (securementRelativeSeqNum - curSecurementRelativeSeqNum);
                    if (securementEndSeqNum > 9 || securementEndSeqNum < curSecurementRelativeSeqNum) {
                        securementEndSeqNum = curSecurementRelativeSeqNum;
                    }
                    combinedSecurementCompatibleValues = [];
                    for (i = curSecurementRelativeSeqNum; i <= securementEndSeqNum; i++) {
                        tempVariableId = "ComponentId_GenericSecurement".concat(i);
                        singleSecurementCompatibleValues = (_getVariable = getVariable(sections, tempVariableId)) === null || _getVariable === void 0 ? void 0 : (_getVariable$values = _getVariable.values) === null || _getVariable$values === void 0 ? void 0 : (_getVariable$values$f = _getVariable$values.filter(function (data) { return !data.incompatible; })) === null || _getVariable$values$f === void 0 ? void 0 : _getVariable$values$f.map(function (data) { return String(data.value); });
                        if (singleSecurementCompatibleValues && singleSecurementCompatibleValues.length > 0)
                            combinedSecurementCompatibleValues = __spreadArray(__spreadArray([], combinedSecurementCompatibleValues, true), singleSecurementCompatibleValues, true);
                    }
                    compatibleValues = Array.from(new Set(combinedSecurementCompatibleValues));
                }
                else {
                    compatibleValues = (_getVariable2 = getVariable(sections, variableId)) === null || _getVariable2 === void 0 ? void 0 : (_getVariable2$values = _getVariable2.values) === null || _getVariable2$values === void 0 ? void 0 : (_getVariable2$values$ = _getVariable2$values.filter(function (data) { return !data.incompatible; })) === null || _getVariable2$values$ === void 0 ? void 0 : _getVariable2$values$.map(function (data) { return String(data.value); });
                }
                newVariableAssignments.push(computeVariableAssignment('ComponentId', compatibleValues, variables));
                layerVariableAssignments = layer === null || layer === void 0 ? void 0 : (_layer$variableAssign3 = layer.variableAssignments) === null || _layer$variableAssign3 === void 0 ? void 0 : _layer$variableAssign3.filter(function (data) { return data.variableId !== 'ExtendedLayerConfigSplit_ExtendedLayerConfigId' && data.variableId !== 'ComponentId'; });
                defaultOperatorVariableAssignments = variables === null || variables === void 0 ? void 0 : variables.filter(function (data) { var _data$id; return ((_data$id = data.id) === null || _data$id === void 0 ? void 0 : _data$id.endsWith('Operator')) && !(layerVariableAssignments !== null && layerVariableAssignments !== void 0 && layerVariableAssignments.find(function (obj) { return obj.variableId === data.id; })); }).map(function (data) { return { variableId: data.id, value: 'Equal' }; });
                if (layerVariableAssignments)
                    newVariableAssignments = __spreadArray(__spreadArray([], newVariableAssignments, true), layerVariableAssignments, true);
                if (defaultOperatorVariableAssignments)
                    newVariableAssignments = __spreadArray(__spreadArray([], newVariableAssignments, true), defaultOperatorVariableAssignments, true);
                return [2 /*return*/, newVariableAssignments];
        }
    });
}); };
export var useGetInitialLayerState = function (variableAssignments, allExtendedLayerConfigs, excludedLayerIds, layers, layerIndex, model, prevAllowedExtendedLayerConfigs) { var logger = useLogger(); var _a = useState(), data = _a[0], setData = _a[1]; var _b = useState(false), loading = _b[0], setLoading = _b[1]; var _c = useState(), error = _c[0], setError = _c[1]; if (!loading) {
    setLoading(true);
    getInitialLayerState(variableAssignments, allExtendedLayerConfigs, excludedLayerIds, layers, layerIndex, model, prevAllowedExtendedLayerConfigs).then(function (initialState) { return setData(initialState); }, function (reason) { return setError(reason); }).catch(function (reason) { setError(reason); logger.error('Error in useGetInitialLayerState', { error: error, msg: error.message }); });
} return { data: data, loading: !data && !error, error: error }; };
export var request = function (variableAssignments, productId, viewId) { return __awaiter(void 0, void 0, void 0, function () { var token, response; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, fetchFreshToken()];
        case 1:
            token = (_a.sent()) || '';
            return [4 /*yield*/, fetch("".concat(process.env.REACT_APP_CONFIGIT_URL), { method: 'post', mode: 'cors', headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', clientId: "".concat(process.env.REACT_APP_CONFIGIT_API_KEY), Authorization: token ? "Bearer ".concat(token) : '' }, body: JSON.stringify({ date: '2017-06-22', language: 'EN', line: { productId: productId, variableAssignments: variableAssignments }, viewId: viewId }) })];
        case 2:
            response = _a.sent();
            return [4 /*yield*/, response.json()];
        case 3: return [2 /*return*/, _a.sent()];
    }
}); }); };
export var flattenSubSections = function (sections) { var _section$sections; var section = sections[0]; var variables = []; if (section.variables)
    variables.concat(section.variables); (_section$sections = section.sections) === null || _section$sections === void 0 ? void 0 : _section$sections.forEach(function (subSection) { if (subSection.variables)
    variables = variables.concat(subSection.variables); }); section.variables = variables; delete section.sections; return sections; };
export var mapWithDatabase = function (sections) {
    for (var i = 0; i < sections.length; i++) {
        var section = sections[i];
        if (section.sections)
            section.sections = mapWithDatabase(section.sections);
        var variables = section.variables;
        if (variables) {
            for (var j = 0; j < variables.length; j++) {
                var variable = variables[j];
                if (variable.id) {
                    var map = CONFIGIT_MAP[variable.id];
                    if (map) {
                        var _variable$id; // if (map.label) {
                        //   variable.name = map.label;
                        // }
                        if (map.properties) {
                            variable.properties = map.properties;
                        }
                        if ((_variable$id = variable.id) !== null && _variable$id !== void 0 && _variable$id.endsWith('Operator')) {
                            if (variable.properties && variable.properties[0].values)
                                variable.properties[0].values[0].rangeValues = variables[j + 1].values;
                        }
                    }
                }
            }
        }
    }
    return sections;
};
export var orderValuesByName = function (values) { return values.sort(function (a, b) { return a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 0; }); };
export var orderAllowedValuesFirst = function (values) { return values.filter(function (value) { return !value.incompatible; }).concat(values.filter(function (value) { return value.incompatible; })); };
var reOrderValues = function (section) { var _section$variables; section.variables = (_section$variables = section.variables) === null || _section$variables === void 0 ? void 0 : _section$variables.map(function (variable) { var id = variable.id; var values = variable.values; if (values && (values === null || values === void 0 ? void 0 : values.length) > 3) {
    if (id && id === 'NavAssembly_WindUplift_view')
        variable.values = orderAllowedValuesFirst(values);
    else
        variable.values = orderAllowedValuesFirst(orderValuesByName(values));
} return variable; }); return section; };
var sortDecimalValues = function (section) { var _section$variables2; section.variables = (_section$variables2 = section.variables) === null || _section$variables2 === void 0 ? void 0 : _section$variables2.map(function (variable) { if (variable.properties && variable.properties[0] && variable.properties[0].value === 'decimal') {
    var _variable$values;
    variable.values = (_variable$values = variable.values) === null || _variable$values === void 0 ? void 0 : _variable$values.sort(function (a, b) { return parseFloat(String(a.value)) - parseFloat(String(b.value)); });
} return variable; }); return section; };
export var getValidLayerConfigs = function (sections) { var _getVariable$values$f2, _getVariable3, _getVariable3$values, _getVariable3$values$; return (_getVariable$values$f2 = (_getVariable3 = getVariable(sections, 'LayerConfig_LayerConfigId')) === null || _getVariable3 === void 0 ? void 0 : (_getVariable3$values = _getVariable3.values) === null || _getVariable3$values === void 0 ? void 0 : (_getVariable3$values$ = _getVariable3$values.filter(function (value) { return !value.incompatible; })) === null || _getVariable3$values$ === void 0 ? void 0 : _getVariable3$values$.map(function (data) { return data; })) !== null && _getVariable$values$f2 !== void 0 ? _getVariable$values$f2 : []; };
export var getAllLayerConfigs = function (sections) { var _getVariable$values2, _getVariable4; return (_getVariable$values2 = (_getVariable4 = getVariable(sections, 'LayerConfig_LayerConfigId')) === null || _getVariable4 === void 0 ? void 0 : _getVariable4.values) !== null && _getVariable$values2 !== void 0 ? _getVariable$values2 : []; };
export var getCompatibleExtendedLayerConfigs = function (sections, allExtendedLayerConfigs) { var _getVariable5, _getVariable5$values, _getVariable5$values$, _getVariable5$values$2; var result = (_getVariable5 = getVariable(sections, 'ExtendedLayerConfigSplit_ExtendedLayerConfigId')) === null || _getVariable5 === void 0 ? void 0 : (_getVariable5$values = _getVariable5.values) === null || _getVariable5$values === void 0 ? void 0 : (_getVariable5$values$ = _getVariable5$values.filter(function (data) { return !data.incompatible; })) === null || _getVariable5$values$ === void 0 ? void 0 : (_getVariable5$values$2 = _getVariable5$values$.map(function (data) { return allExtendedLayerConfigs.find(function (obj) { return obj.ExtendedLayerConfigId === Number(data.value); }); })) === null || _getVariable5$values$2 === void 0 ? void 0 : _getVariable5$values$2.filter(function (data) { return !!data; }); return result !== null && result !== void 0 ? result : []; };
export var getVariable = function (sections, variableId) { for (var i = 0; i < sections.length; i++) {
    var _sections$i$variables;
    var variable = (_sections$i$variables = sections[i].variables) === null || _sections$i$variables === void 0 ? void 0 : _sections$i$variables.find(function (data) { return data.id === variableId; });
    if (variable)
        return variable;
} };
export var updateFilters = function (section, newFilters) { var filterApplied = false; if (!section.variables)
    return; for (var i = 0; i < section.variables.length; i++) {
    var _variable$values2;
    var variable = section.variables[i];
    var configitValue = (_variable$values2 = variable.values) === null || _variable$values2 === void 0 ? void 0 : _variable$values2.find(function (value) { return value.assigned; });
    if (variable.id && configitValue) {
        var value = configitValue ? configitValue.value : undefined;
        var metadata = CONFIGIT_MAP[variable.id];
        if (metadata)
            if (metadata.tableName) {
                filterApplied = true;
                newFilters[metadata.tableName + 'Id'] = { _eq: parseInt(String(value)) };
            }
            else if (metadata.colName) {
                var _metadata$properties, _metadata$properties$, _metadata$properties$2, _metadata$properties$3;
                if (((_metadata$properties = metadata.properties) === null || _metadata$properties === void 0 ? void 0 : (_metadata$properties$ = _metadata$properties[0]) === null || _metadata$properties$ === void 0 ? void 0 : (_metadata$properties$2 = _metadata$properties$.values) === null || _metadata$properties$2 === void 0 ? void 0 : (_metadata$properties$3 = _metadata$properties$2[0]) === null || _metadata$properties$3 === void 0 ? void 0 : _metadata$properties$3.type) === 'Boolean') {
                    filterApplied = true;
                    newFilters[metadata.colName] = { _eq: String(value).toLowerCase() === 'true' };
                }
                else {
                    var _section$variables$va;
                    var key = value === 'BiggerOrEqual' ? '_gte' : value === 'Equal' ? '_eq' : '_lte';
                    var val = (_section$variables$va = section.variables[i + 1].values) === null || _section$variables$va === void 0 ? void 0 : _section$variables$va.find(function (value) { return value.assigned; });
                    if (val) {
                        filterApplied = true;
                        newFilters[metadata.colName] = {};
                        newFilters[metadata.colName][key] = String(parseFloat(String(val.name)));
                    }
                }
            }
            else if (metadata.addFilters) {
                filterApplied = true;
                metadata.addFilters(newFilters, parseInt(String(value)));
            }
    }
} return filterApplied; };
export var mapInferredLayerWithConfigurations = function (layers, inferredLayers) {
    var layerTypeIdNumOccurrence = new Map();
    for (var i = 0; i < inferredLayers.length; i++) {
        if (!layerTypeIdNumOccurrence.has(inferredLayers[i].id))
            layerTypeIdNumOccurrence.set(inferredLayers[i].id, 1);
        else
            layerTypeIdNumOccurrence.set(inferredLayers[i].id, layerTypeIdNumOccurrence.get(inferredLayers[i].id) + 1);
    } // trackDuplicateLayer is used to track # of occurrence of duplicate layers when loop against inferredLayers array
    var trackDuplicateLayer = new Map();
    var resultLayers = [];
    var _loop_1 = function (i) {
        var prev = void 0;
        if (!inferredLayers[i].autoSelected) {
            if (layerTypeIdNumOccurrence.get(inferredLayers[i].id) === 1) {
                prev = layers.find(function (data) { return data.id === inferredLayers[i].id; });
            }
            else { // Duplicate layers with same layer type ids
                if (!trackDuplicateLayer.has(inferredLayers[i].id)) {
                    trackDuplicateLayer.set(inferredLayers[i].id, 1);
                    prev = layers.find(function (data) { return data.id === inferredLayers[i].id; });
                }
                else { // Handle prev for the second or more occurrence of duplicate layers
                    var existingNumOccurrence = trackDuplicateLayer.get(inferredLayers[i].id);
                    var newNumOccurrence = existingNumOccurrence + 1;
                    trackDuplicateLayer.set(inferredLayers[i].id, newNumOccurrence + 1);
                    var matched = 0;
                    for (var j = 0; j < layers.length; j++) {
                        if (layers[j].id === inferredLayers[i].id) {
                            matched++;
                            if (matched === newNumOccurrence) {
                                prev = layers[j];
                                break;
                            }
                        }
                    }
                }
            }
        }
        if (prev) {
            if (prev.isSecurementLayer) {
                var cur = inferredLayers[i];
                resultLayers.push(_objectSpread(_objectSpread({}, prev), {}, { fromLayerNum: cur.fromLayerNum, fromLayerTypeId: cur.fromLayerTypeId, toLayerNum: cur.toLayerNum, toLayerTypeId: cur.toLayerTypeId }));
            }
            else
                resultLayers.push(prev);
        }
        else
            resultLayers.push(inferredLayers[i]);
    };
    for (var i = 0; i < inferredLayers.length; i++) {
        _loop_1(i);
    }
    return resultLayers;
};
export var getInitialSelfSecurementState = function (variableAssignments, allExtendedLayerConfigs, excludedLayerIds, layers, layerIndex, modelSelfSecurement, modelBaseLayer) { return __awaiter(void 0, void 0, void 0, function () {
    var _variables$find2, _layer$selfSecurement, _layer$selfSecurement2, exchangeModelVariableAssignments, securementRelativeSeqNum, curSecurementRelativeSeqNum, numOfDuplicatesVA, layer, layerData, layerProps, relativeSeqNum, numOfDuplicates, i, i, numOfDuplicatesVA, sections, section, variables, newVariableAssignments, compatibleExtendedLayerConfigs, matchingExtendedLayerConfigs, allowedComponentIds, selfSecurementVariableAssignments, defaultOperatorVariableAssignments;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                exchangeModelVariableAssignments = __spreadArray([], variableAssignments.filter(function (data) { return data.value; }), true);
                securementRelativeSeqNum = 0, curSecurementRelativeSeqNum = 0;
                layers.forEach(function (layer, index) { var _layer$variableAssign4, _layer$variableAssign5; if (layer.isSecurementLayer)
                    securementRelativeSeqNum++; if (layerIndex === index) {
                    if (layer.isSecurementLayer)
                        curSecurementRelativeSeqNum = securementRelativeSeqNum;
                    return;
                } var allowedValues = layer === null || layer === void 0 ? void 0 : (_layer$variableAssign4 = layer.variableAssignments) === null || _layer$variableAssign4 === void 0 ? void 0 : (_layer$variableAssign5 = _layer$variableAssign4.find(function (data) { return data.variableId === 'ComponentId_view'; })) === null || _layer$variableAssign5 === void 0 ? void 0 : _layer$variableAssign5.allowedValues; if (allowedValues) {
                    var layerData_2 = getLayerDataById(layer.id);
                    var layerProps_2 = getLayerProps(layerData_2);
                    var relativeSeqNum_2 = 1, numOfDuplicates_2 = 0;
                    var variableId = "ComponentId_".concat(layer.model);
                    if (layerProps_2.hasDuplicates) {
                        if (!layer.isSecurementLayer) {
                            for (var i = 0; i < layers.length; i++) {
                                if (i === index)
                                    break;
                                if (layers[i].id === layer.id)
                                    relativeSeqNum_2++;
                            }
                            for (var i = 0; i < layers.length; i++) {
                                if (layers[i].id === layer.id)
                                    numOfDuplicates_2++;
                            }
                            variableId = "ComponentId_".concat(layer.model).concat(relativeSeqNum_2);
                            var numOfDuplicatesVA = { variableId: "NumOfDuplicates_".concat(layer.model), value: String(numOfDuplicates_2) };
                            exchangeModelVariableAssignments.push(numOfDuplicatesVA);
                        }
                        else {
                            variableId = "ComponentId_GenericSecurement".concat(securementRelativeSeqNum);
                            exchangeModelVariableAssignments.push({ variableId: "LayerTypeId_GenericSecurement".concat(relativeSeqNum_2), value: layer.id });
                        }
                    }
                    exchangeModelVariableAssignments.push({ variableId: variableId, type: 'AllowedValues', allowedValues: allowedValues });
                } });
                if (securementRelativeSeqNum) {
                    numOfDuplicatesVA = { variableId: "NumOfDuplicates_GenericSecurement", value: String(securementRelativeSeqNum) };
                    exchangeModelVariableAssignments.push(numOfDuplicatesVA);
                }
                layer = layers[layerIndex];
                layerData = getLayerDataById(layer.id);
                layerProps = getLayerProps(layerData);
                relativeSeqNum = 1, numOfDuplicates = 0;
                if (layerProps.hasDuplicates && !layer.isSecurementLayer) {
                    for (i = 0; i < layers.length; i++) {
                        if (i === layerIndex)
                            break;
                        if (layers[i].id === layer.id)
                            relativeSeqNum++;
                    }
                    for (i = 0; i < layers.length; i++) {
                        if (layers[i].id === layer.id)
                            numOfDuplicates++;
                    }
                    numOfDuplicatesVA = { variableId: "NumOfDuplicates_".concat(modelBaseLayer), value: String(numOfDuplicates) };
                    exchangeModelVariableAssignments.push(numOfDuplicatesVA);
                }
                return [4 /*yield*/, configure(exchangeModelVariableAssignments)];
            case 1:
                sections = _a.sent();
                return [4 /*yield*/, configureLayer([], modelSelfSecurement)];
            case 2:
                section = _a.sent();
                variables = section.variables;
                newVariableAssignments = [];
                compatibleExtendedLayerConfigs = getCompatibleExtendedLayerConfigs(sections, allExtendedLayerConfigs);
                matchingExtendedLayerConfigs = LayerApi.getMatchingLayerConfigs(layers, excludedLayerIds, compatibleExtendedLayerConfigs);
                newVariableAssignments.push({ variableId: 'ExtendedLayerConfigSplit_ExtendedLayerConfigId', type: 'AllowedValues', allowedValues: matchingExtendedLayerConfigs.map(function (data) { return { type: 'SingletonValue', value: String(data.ExtendedLayerConfigId) }; }) }); // Products allowed
                allowedComponentIds = variables === null || variables === void 0 ? void 0 : (_variables$find2 = variables.find(function (obj) { return obj.id === 'ComponentId'; })) === null || _variables$find2 === void 0 ? void 0 : _variables$find2.values;
                newVariableAssignments.push({ variableId: 'ComponentId', type: 'AllowedValues', allowedValues: allowedComponentIds });
                selfSecurementVariableAssignments = layer === null || layer === void 0 ? void 0 : (_layer$selfSecurement = layer.selfSecurement) === null || _layer$selfSecurement === void 0 ? void 0 : (_layer$selfSecurement2 = _layer$selfSecurement.variableAssignments) === null || _layer$selfSecurement2 === void 0 ? void 0 : _layer$selfSecurement2.filter(function (data) { return data.variableId !== 'ExtendedLayerConfigSplit_ExtendedLayerConfigId' && data.variableId !== 'ComponentId'; });
                defaultOperatorVariableAssignments = variables === null || variables === void 0 ? void 0 : variables.filter(function (data) { var _data$id2; return ((_data$id2 = data.id) === null || _data$id2 === void 0 ? void 0 : _data$id2.endsWith('Operator')) && !(selfSecurementVariableAssignments !== null && selfSecurementVariableAssignments !== void 0 && selfSecurementVariableAssignments.find(function (obj) { return obj.variableId === data.id; })); }).map(function (data) { return { variableId: data.id, value: 'Equal' }; });
                if (selfSecurementVariableAssignments)
                    newVariableAssignments = __spreadArray(__spreadArray([], newVariableAssignments, true), selfSecurementVariableAssignments, true);
                if (defaultOperatorVariableAssignments)
                    newVariableAssignments = __spreadArray(__spreadArray([], newVariableAssignments, true), defaultOperatorVariableAssignments, true);
                return [2 /*return*/, newVariableAssignments];
        }
    });
}); };
export var useGetInitialSelfSecurementState = function (variableAssignments, allExtendedLayerConfigs, excludedLayerIds, layers, layerIndex, modelSelfSecurement, modelBaseLayer) { var logger = useLogger(); var _a = useState(), data = _a[0], setData = _a[1]; var _b = useState(false), loading = _b[0], setLoading = _b[1]; var _c = useState(), error = _c[0], setError = _c[1]; if (!loading) {
    setLoading(true);
    getInitialSelfSecurementState(variableAssignments, allExtendedLayerConfigs, excludedLayerIds, layers, layerIndex, modelSelfSecurement, modelBaseLayer).then(function (initialState) { return setData(initialState); }, function (reason) { return setError(reason); }).catch(function (reason) { setError(reason); logger.error('Error in useGetInitialSelfSecurementState', { error: error, msg: error.message }); });
} return { data: data, loading: !data && !error, error: error }; };
