var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { ApolloClient, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { azureAADPublicClientApplication } from './Auth';
import { fetchFreshToken } from './AuthHelper';
import { apolloCache } from './models/CacheAndSettings';
export var fallbackURL = 'http://localhost:8080/v1/graphql';
if (!process.env.REACT_APP_GRAPHQL_API_URL) {
    console.log("No env variable found for REACT_APP_GRAPHQL_API_URL. Using fallback ".concat(fallbackURL));
}
var httpLink = createHttpLink({ uri: process.env.REACT_APP_GRAPHQL_API_URL || fallbackURL, credentials: 'same-origin' });
var authLink = setContext(function (request, _ref) { return __awaiter(void 0, void 0, void 0, function () {
    var _request$context, _activeUser$idTokenCl, _activeUser$idTokenCl2, headers, token, requestedRole, roleToUse, activeUser;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                headers = _ref.headers;
                return [4 /*yield*/, fetchFreshToken()];
            case 1:
                token = (_a.sent()) || '';
                requestedRole = (request === null || request === void 0 ? void 0 : (_request$context = request.context) === null || _request$context === void 0 ? void 0 : _request$context.preferredRole) || 'roofnav-user';
                roleToUse = 'roofnav-user';
                activeUser = azureAADPublicClientApplication.getActiveAccount();
                if ( // eslint-disable-next-line @typescript-eslint/no-explicit-any
                activeUser !== null && activeUser !== void 0 && (_activeUser$idTokenCl = activeUser.idTokenClaims) !== null && _activeUser$idTokenCl !== void 0 && (_activeUser$idTokenCl2 = _activeUser$idTokenCl['dce-roles']) !== null && _activeUser$idTokenCl2 !== void 0 && _activeUser$idTokenCl2.some(function (currentUserRole) { return currentUserRole === requestedRole; })) {
                    roleToUse = requestedRole;
                }
                return [2 /*return*/, { headers: _objectSpread(_objectSpread({}, headers), {}, { 'hasura-client-name': process.env.REACT_APP_X_HASURA_CLIENT_NAME }, process.env.REACT_APP_X_HASURA_ADMIN_SECRET && { 'x-hasura-admin-secret': process.env.REACT_APP_X_HASURA_ADMIN_SECRET } || _objectSpread({ Authorization: token ? "Bearer ".concat(token) : '' }, roleToUse && { 'x-hasura-role': roleToUse })) }];
        }
    });
}); });
export var apolloClient = new ApolloClient({ link: authLink.concat(httpLink), cache: apolloCache });
